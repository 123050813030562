.login-model {
  .tabs {
    :global(.ant-tabs-nav) {
      &::before {
        display: none;
      }
    }
    :global(.ant-tabs-tab) {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}
