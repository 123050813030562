.agreement-text {
  font-family: PingFangSC, PingFang SC;
  color: #999999;
  font-size: 12px;
}
.input {
  &:focus {
    border-color: var(--primary-color);
    box-shadow: unset;
  }
  input {
    &:focus {
      border-color: var(--primary-color);
      box-shadow: unset;
    }
  }
}

.input-code {
  &:global(.active) {
    :global(.ant-input-group-addon) {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: #fff;
    }
  }
  :global(.ant-input-group-addon) {
    width: 120px;
    background-color: #e9e9e9;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
  }
}

.submit {
  width: 100%;
  border-radius: 20px !important;
}
