.header {
  height: 80px !important;
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #fff !important;
  padding: 0 !important;
  .header-content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    height: 80px;
  }
  .logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    .logo-png {
      height: 30px;
    }
    .logo-text {
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      margin-left: 30px;
      white-space: nowrap;
    }
  }
  .menu {
    background-color: #fff;
    border: none;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    :global(.ant-menu-item-selected) {
      color: var(--normal-font-color);
      &::after {
        display: none;
      }
    }
    :global(.ant-menu-item) {
      color: var(--normal-font-color);
      font-size: 18px;
      padding: 0 18px;
      &:focus {
        outline: none;
      }
      &::after {
        display: none;
      }
    }
  }
  .location {
    display: flex;
    align-items: center;
    margin-right: 100px;
    > img {
      width: 15px;
      margin-right: 4px;
    }
  }
}
.layout-content {
  background-color: var(--bg-color);
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: #333333 !important;
  font-size: 14px !important;
  font-family: PingFangSC, PingFang SC !important;
  font-weight: 400 !important;
  color: #aeaeae !important;
  a {
    font-size: 14px !important;
    font-family: PingFangSC, PingFang SC !important;
    font-weight: 400 !important;
    color: #aeaeae !important;
    margin-left: 20px;
    img {
      vertical-align: middle;
      margin-right: 4px;
    }
  }
}
