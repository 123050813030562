:root {
  --min-width: 1200px;

  // 商品卡片尺寸
  --product-card-width: 287px; // 287
  --product-card-height: 400px; // 287
  --product-card-space: 80px; // 80
  // 内容区域的最大宽度
  --content-max-width: calc(var(--product-card-width) * 4 + var(--product-card-space) * 3);
  --min-edge-spacing: 80px;

  --bg-color: #f1f1f1;

  --normal-font-color: #666666;

  --primary-color: #2f2f2f;
}

html {
  background-color: #fff;
}
body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: var(--min-width);
  background-color: var(--bg-color);
}

iframe {
  display: none;
}
// > 1564  width 287 space: 80
//  1440 < screen < 1564 width 260 space: 60
// screen < 1440 width 240 space: 40
@media screen and (max-width: 1564px) {
  :root {
    --product-card-width: 260px;
    --product-card-space: 60px;
    --product-card-height: 370px;
  }
}

@media screen and (max-width: 1440px) {
  :root {
    --min-edge-spacing: 60px;
    --product-card-width: 240px;
    --product-card-space: 40px;
    --product-card-height: 350px;
  }
}
