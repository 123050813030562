.layout {
  width: 100%;
  height: 100%;
  .content {
    max-width: var(--content-max-width);
    padding: 0 var(--min-edge-spacing);
    box-sizing: content-box;
    margin: 0 auto;
  }
}
